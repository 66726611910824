<template>
  <div>
    <b-card>
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <div class="form-row justify-content-left align-items-center">
          <div class="col-md-2">
            <v-select label="title" required :searchable="false" v-model="filters.perPage" :options="perPageOptions"
              @input="setPerPageSelected" :clearable="false" />
          </div>

          <div class="col-md-4">
            <div class="form-group mb-md-0">
              <input type="text" v-model="filters.company_name" class="form-control" placeholder="Nome fantasia" />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <input type="text" v-model="filters.state" class="form-control" placeholder="UF" />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <input type="tel" v-mask="['##.###.###/####-##', '###.###.###-##']" v-model="filters.document"
                class="form-control" placeholder="CPF/CNPJ" />
            </div>
          </div>

          <div class="col-md-1">
            <button type="submit" class="btn btn-primary btn-block">
              <div v-if="loading">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <i class="bi bi-search cursor-pointer cursor"></i>
              </div>
            </button>
          </div>
        </div>
      </b-form>
    </b-card>
    <b-card no-body>
      <b-table sticky-header :items="dataItems" responsive :fields="tableColumns" primary-key="id" show-empty
        :busy="loading" empty-text="Nenhum registro encontrado" class="position-relative">
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <template #cell(type)="{ item }">
          {{ item.type.title }}
        </template>

        <template #cell(companies)="{ item }">
          <span v-if="item.companies">
            {{ item.companies }}
          </span>
        </template>

        <template #cell(bank)="{ item }">
          <span v-if="item.bank">
            {{ item.bank.title }}
          </span>
        </template>

        <template #cell(category)="{ item }">
          {{ item.category.title }}
        </template>

        <template #cell(actions)="data">
          <div
            v-if="
              $can('Conta Bancária - Editar', 'Financeiro - ADM') ||
              $can('Conta Bancária - Excluir', 'Financeiro - ADM')
            "
            class="form-row align-items-center justify-content-center"
          >
            <div class="col-md-6 text-center">
              <feather-icon
                icon="TrashIcon"
                class="cursor-pointer cursor text-danger"
                size="16"
                v-if="$can('Conta Bancária - Excluir', 'Financeiro - ADM')"
                @click="confirmDelete(data.item.uuid)"
              />
            </div>
            <div class="col-md-6 text-center">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
                v-if="$can('Conta Bancária - Editar', 'Financeiro - ADM')"
                @click="modalEdit(data.item.uuid)"
              />
            </div>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner small class="align-middle" style="margin-right: 3px"></b-spinner>
            <strong> carregando...</strong>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable :rows-table="rowsTable" :current-page="filters.currentPage" :per-page="filters.perPage"
        :total-rows="totalRows" />
    </b-card>

    <b-modal id="modal-create-order-bank-accounts" modal-class="modal-create" :title="titleModal" centered hide-footer>
      <form @submit.prevent="submitCreate">
        <div class="form-row">
          <div class="col-md-5">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i> Nome da conta
              </label>
              <input type="text" v-model="formModal.name" :class="{ 'is-invalid': $v.formModal.name.$error }" class="form-control">
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i> 
                Banco
              </label>
              <v-select label="title" required :searchable="true" v-model="formModal.bank" :options="optionsBanks"
                :clearable="true" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">
                Agência
              </label>
              <input type="text" v-model="formModal.agency" class="form-control">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">
                Número da conta
              </label>
              <input type="text" v-model="formModal.account_number" class="form-control">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-11">
            <h4 class="mb-0">
              Empresas
              <small class="d-block mt-50">Vincule 1 ou mais contas empresas a esta conta bancária.</small>
            </h4>
          </div>
          <div class="col-md-1 d-flex-left justify-content-end" style="column-gap: 5px;">
            <button type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              style="height: 33px; padding: 0px!important" @click="addCompany"
              class="btn-block btn btn-sm btn-primary">
              <i class="bi bi-plus"></i>
            </button>
          </div>
        </div>

        <div class="form-row align-items-center mt-1" :class="{ 'mb-1': i !== formModal.companies.length - 1 }" v-for="(el, i) in formModal.companies" :key="`business-strategic-key-${i}`">
          <div class="col-md-10">
            <div class="form-group mb-0">
              <v-select label="title" v-model="el.comapny" item-text="title" item-value="code"
                placeholder="Conta bancária" :options="optionsCompanies">
                <span slot="no-options">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-0">
              <button type="button" class="btn btn-sm btn-danger btn-block" style="padding: 14px 10px !important;" @click="deleteCompany(i)">
                <i class="bi bi-trash3"></i>
              </button>
            </div>
          </div>
        </div>
        
        <div class="form-row justify-content-end modal-footer-custom">
          <div class="col-md-7 col-12">
            <button type="button" @click="$bvModal.hide('modal-create-order-bank-accounts')"
              class="btn btn-light float-left">
              Fechar
            </button>
            <ButtonsActionsFooter variant="success" :submited="submited" :text="textSubmit" subtext="Aguarde..." />
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import Ripple from "vue-ripple-directive";
import CustomPaginateTable from "@/views/components/custom/PaginationTable.vue";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BForm,
    BTooltip,
    vSelect,
    CustomPaginateTable,
    StatisticCardVertical,
    BSpinner,
    ButtonsActionsFooter,
  },
  data() {
    return {
      loading: false,
      filters: {
        company_name: "",
        state: "",
        document: "",
        currentPage: 1,
        perPage: 100,
      },
      textSubmit: "",
      submited: false,
      formModal: this.getEmptyFormModal(),
      perPageOptions: [
        { title: "100", value: 100 },
        { title: "200", value: 200 },
      ],
      tableColumns: [
        { key: "name", label: "Nome", class: "text-left text-elipse-400" },
        { key: "bank", label: "Banco", thStyle: "width: 300px", class: "text-elipse-400" },
        { key: "companies", label: "Empresas", thStyle: "width: 50px", class: "text-center" },
        { key: "actions", label: "", class: "text-center", thStyle: "width: 80px" },
      ],
      optionsBankAccountTypes: [],
      optionsBankAccountCategories: [],
      optionsCompanies: [],
      dataItems: [],
      optionsStatus: [],
      optionsStrategicUnits: [],
      totalRows: 0,
      rowsTable: 0,
      titleModal: "",
      optionsBanks: [],
    };
  },
  validations: {
    formModal: {
      name: { required },
      bank: { required },
    },
  },
  computed: {
    isFormValid() {
      return !this.$v.formModal.$error;
    },
  },
  methods: {
    getEmptyFormModal() {
      return {
        bank: "",
        name: "",
        note: "",
        account_number: "",
        agency: "",
        companies: [],
      };
    },
    async fetchOptions() {
      try {
        const [
          banks,
          accountTypes,
          accountCategories,
          strategicUnits,
          companies,
        ] = await Promise.all([
          this.$store.dispatch("Bank/forSelect"),
          this.$store.dispatch("BankAccount/forSelectTypes"),
          this.$store.dispatch("BankAccount/forSelectCategories"),
          this.$store.dispatch("StrategicUnit/forSelect"),
          this.$store.dispatch("Company/search", ""),
        ]);

        this.optionsBanks = banks;
        this.optionsBankAccountTypes = accountTypes;
        this.optionsBankAccountCategories = accountCategories;
        this.optionsStrategicUnits = strategicUnits;
        this.optionsCompanies = companies;
      } catch (error) {
        console.error("Erro ao buscar opções:", error);
        this.notifyDefault("error", "Erro ao carregar opções.");
      }
    },
    addCompany() {
      this.formModal.companies.push({ id: "" });
    },
    deleteCompany(index) {
      this.formModal.companies.splice(index, 1);
    },
    async modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      try {
        const res = await this.$store.dispatch("BankAccount/show", uuid);
        this.formModal = res;
        this.uuid = this.formModal.uuid;
        this.$bvModal.show("modal-create-order-bank-accounts");
      } catch (error) {
        console.error("Erro ao editar:", error);
        this.notifyDefault("error", "Erro ao carregar dados para edição.");
      }
    },
    async submitCreate() {
      this.$v.formModal.$touch();
      if (!this.isFormValid) {
        this.notifyDefault("error", "Por favor, preencha os campos obrigatórios.");
        return;
      }

      const data = {
        name: this.formModal.name,
        bank: this.formModal.bank?.code || "",
        companies: this.formModal.companies || [],
        agency: this.formModal.agency,
        account_number: this.formModal.account_number,
      };

      try {
        if (this.textSubmit === "Cadastrar") {
          await this.$store.dispatch("BankAccount/store", data);
        } else {
          await this.$store.dispatch("BankAccount/update", { uuid: this.uuid, data });
        }
        this.notifyDefault("success", `Conta bancária ${this.textSubmit === "Cadastrar" ? "cadastrada" : "atualizada"} com sucesso.`);
        this.getData();
        this.clearModal();
        this.$bvModal.hide("modal-create-order-bank-accounts");
      } catch (error) {
        console.error("Erro ao submeter:", error);
        this.notifyDefault("error", "Erro ao submeter o formulário.");
      } finally {
        this.submited = false;
      }
    },
    async confirmDelete(uuid) {
      try {
        const result = await this.$swal({
          title: "Quer excluir?",
          text: "Esta ação será irreversível!",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Sim, quero excluir!",
          customClass: { confirmButton: "btn btn-danger", cancelButton: "btn btn-light ml-1" },
          buttonsStyling: false,
        });

        if (result.value) {
          await this.$store.dispatch("BankAccount/delete", uuid);
          this.getData();
          this.$swal({
            icon: "success",
            title: "Deletado!",
            text: "Operação realizada com sucesso.",
            customClass: { confirmButton: "btn btn-success" },
          });
        }
      } catch (error) {
        console.error("Erro ao deletar:", error);
        this.notifyDefault("error", "Erro ao deletar a conta bancária.");
      }
    },
    clearModal() {
      this.formModal = this.getEmptyFormModal();
    },
    async getData() {
      this.loading = true;
      try {
        const res = await this.$store.dispatch("BankAccount/all", this.filters);
        if (res) {
          this.dataItems = res.data;
          this.rowsTable = res.data.length;
          this.totalRows = res.meta.total;
          this.filters.currentPage = res.meta.current_page;
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
        this.notifyDefault("error", "Erro ao buscar os dados.");
      } finally {
        this.loading = false;
      }
    },
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      this.getData();
    },
    notifyDefault(type, message) {
      this.$swal({
        icon: type === "success" ? "success" : "error",
        title: type === "success" ? "Sucesso!" : "Erro!",
        text: message,
        customClass: {
          confirmButton: type === "success" ? "btn btn-success" : "btn btn-danger",
        },
      });
    },
  },
  async mounted() {
    this.getData();
    await this.fetchOptions();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:modal-create-order-bank-accounts", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar";
      this.clearModal();
      this.$bvModal.show("modal-create-order-bank-accounts");
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:modal-create-order-bank-accounts");
  },
  directives: {
    Ripple,
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.per-page-selector {
  width: 90px;
}
</style>
