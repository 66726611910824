var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "text-center" },
    [
      _c(
        "b-avatar",
        {
          staticClass: "mb-1",
          staticStyle: { "font-size": "1.2rem" },
          attrs: { variant: "light-" + _vm.color },
        },
        [_c("i", { class: "bi " + _vm.icon, attrs: { size: "21" } })]
      ),
      _c("div", { staticClass: "truncate" }, [
        _c("h2", { staticClass: "mb-25 font-weight-bolder" }, [
          _vm._v(" " + _vm._s(_vm.statistic) + " "),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.statisticTitle))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }